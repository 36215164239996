.highlighted-status {
  font-weight: bold;
  color: #ff5722; /* Bright color to stand out */
  font-style: italic;
  padding-left: 5px;
}

.blured-line {
  color: #c6c6c6;
}

body {
  min-height: 100vh;
  position: relative;
  padding-bottom: 6rem;
}

iframe {
  display: none;
}

.container {
  max-width: 1280px !important;
}

.modal_table_wrapper table {
  font-family: "Source Sans Pro", sans-serif;
  border: none;
  width: 100%;
  text-align: left;
  table-layout: fixed;
  border-collapse: collapse;
  background-color: white;
}

.modal_table_wrapper table td,
th {
  vertical-align: center;
  text-overflow: ellipsis;
  word-wrap: break-word;
  padding: 8px;
}

.modal_table_wrapper table tr:nth-child(even) {
  background: #ececec;
}

.modal_table_wrapper hr {
  border-top: 1px solid #c1c1c1;
}

.modal_table_wrapper .no-break {
  page-break-inside: avoid !important;
}

.modal_table_wrapper table,
tr,
td,
div {
  page-break-inside: auto !important;
}

.modal_table_wrapper th {
  width: 24%;
  border-right: 1px solid #c1c1c1 !important;
  vertical-align: top;
}

.modal_pretext_wrapper {
  max-height: 600px;
  overflow-y: auto;
}

.modal_pretext {
  white-space: break-spaces;
  color: #20699c;
  font-size: 1rem;
  line-height: 1.15;
  padding: 0 24px;
}

.highlighted-status {
  font-weight: bold;
  color: #ff5722;
  /* Bright color to stand out */
  font-style: italic;
  padding-left: 5px;
}
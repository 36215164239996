
.pagination .page a, .pagination .previous a, .pagination .next a, .pagination .active a  {
    padding: 14px;
    cursor: pointer;
    border-radius: 10px;
}

.pagination .page a:hover, .pagination .previous a:hover, .pagination .next a:hover  {
    background-color: #4285f4;
    color: white !important;
}

.pagination .active a {
    background-color: #4285f4;
    color: white !important;
    box-shadow: 
        0rem 0.1875rem 0.1875rem 0rem rgb(26 115 232 / 15%), 
        0rem 0.1875rem 0.0625rem -0.125rem rgb(26 115 232 / 20%), 
        0rem 0.0625rem 0.3125rem 0rem rgb(26 115 232 / 15%);
}

.pagination {
    margin-top: 2rem !important;
    justify-content: end;
}



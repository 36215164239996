
.error_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 24px;
}

.error_message {
  color:darkred;
}